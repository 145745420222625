body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  padding-top: 65px;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-reference {
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #666;
}

.App-relatedwords {
  color: #666;
}

.Error {
  color: red;
}

.transliteration-deva {
  display: inline;
  font-size: 2.5rem;
}

.transliteration-latn {
  color: #666;
  display: inline;
  font-size: 2rem;
}

.transliteration-newa {
  color: #666;
  display: inline;
  font-size: 0.9rem;
}

.audio-image {
  cursor: pointer;
}

@font-face {
  font-family: 'NewaNano';
  src: url('/src/fonts/NotoSansNewa-Regular.otf');
}

.NewaNano {
  font-family: 'NewaNano' !important;
  font-size: 1.4rem !important;
  line-height: 1.4rem !important;
}
.Preeti {
  font-family: 'Preeti' !important;
  font-size: 1.4rem !important;
}
